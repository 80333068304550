exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chi-siamo-index-js": () => import("./../../../src/pages/chi-siamo/index.js" /* webpackChunkName: "component---src-pages-chi-siamo-index-js" */),
  "component---src-pages-contatti-index-js": () => import("./../../../src/pages/contatti/index.js" /* webpackChunkName: "component---src-pages-contatti-index-js" */),
  "component---src-pages-cookies-index-js": () => import("./../../../src/pages/cookies/index.js" /* webpackChunkName: "component---src-pages-cookies-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-index-js": () => import("./../../../src/pages/policy/index.js" /* webpackChunkName: "component---src-pages-policy-index-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-r-and-d-index-js": () => import("./../../../src/pages/r-and-d/index.js" /* webpackChunkName: "component---src-pages-r-and-d-index-js" */),
  "component---src-pages-servizi-index-js": () => import("./../../../src/pages/servizi/index.js" /* webpackChunkName: "component---src-pages-servizi-index-js" */),
  "component---src-pages-wip-index-js": () => import("./../../../src/pages/WIP/index.js" /* webpackChunkName: "component---src-pages-wip-index-js" */)
}

